/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Prompt|Sarabun&display=swap');
@charset "UTF-8";

/* Module */
@import '/node_modules/bootstrap-icons/font/bootstrap-icons.css';
@import '/node_modules/country-select-js/build/css/countrySelect.min.css';
@import './animatedBooks.css';


body {
  background-image: url('/images/bg-new.jpg');
}

section {
    min-height: 100vh;
}

section#footer {
    min-height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Prompt', sans-serif;
}

.bg-navbar-home {
  background-color:#9b9b9b40;
}

.color-navbar-collapse > ul > li > a,
.navbar-toggler-icon {
  font-weight: bolder;
  color: black;
  text-shadow: 1px 0 2px white, 0 0 4px white;
}

.card-fade {
  min-height: 100px;
}

.card, .card-img-top, iframe.youtube {
  box-shadow: 0 ;
  transition: box-shadow .2s ease-out .0s;
}

.card:hover, .card-img-top:hover, iframe.youtube {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.text-fade, .card-title {
  text-shadow: 0 ;
  transition: text-shadow .2s ease-out .0s;
}

.text-fade:hover, .card-title:hover {
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.40), 5px 5px 12px rgba(0, 0, 0, 0.40);
}


